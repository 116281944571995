:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -o-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
.footer-section {
  background-color: var(--First-color);
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.footer-top {
  padding-top: 96px;
  padding-bottom: 50px;
}
.footer-top p,
.company-footer-contact-list li {
  color: var(--Fourth-color);
}
.company-footer-contact-list {
  margin-top: 10px;
}
.company-footer-contact-list li {
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.company-footer-contact-list li + li {
  margin-top: 5px;
}
.company-footer-contact-list li i {
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
}

.footer-top .footer-logo {
  background-color: var(--Fourth-color);
  padding: 5px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  max-width: 200px;
  border-radius: 5px;
}
.widget-title {
  text-transform: capitalize;
}
.footer-top .widget-title {
  color: var(--Fourth-color);
  margin-bottom: 40px;
}
.courses-link-list li + li {
  margin-top: 10px;
}
.courses-link-list li a {
  color: var(--Fourth-color);
  text-transform: capitalize;
  font-family: var(--para-font);
  font-weight: 400;
}
.courses-link-list li a:hover {
  color: var(--Third-color);
}
.courses-link-list li i {
  margin-right: 5px;
}
.footer-top .small-post-title a {
  font-family: var(--para-font);
  color: var(--Fourth-color);
  font-weight: 400;
}
.small-post-item .post-date {
  color: var(--Third-color);
  margin-bottom: 3px;
  font-family: var(--para-font);
  font-weight: 400;
}
.small-post-list li + li {
  margin-top: 30px;
}
.news-letter-form {
  margin-top: 15px;
}
.news-letter-form input {
  width: 100%;
  padding: 12px 25px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: none;
  background-color: var(--Fourth-color);
}
.news-letter-form input[type="submit"] {
  width: auto;
  border: none;
  background-color: var(--Second-color);
  padding: 9px 30px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--Fourth-color);
  margin-top: 10px;
}
.footer-bottom {
  padding: 13px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.149);
}
.copy-right-text {
  color: var(--Fourth-color);
}
.copy-right-text a {
  color: var(--Third-color);
}
.terms-privacy li + li {
  margin-left: 30px;
}
.terms-privacy li a {
  color: var(--Fourth-color);
  position: relative;
}
.terms-privacy li a:after {
  position: absolute;
  content: "-";
  color: var(--Fourth-color);
  display: inline-block;
  top: 0;
  right: -18px;
}
.terms-privacy li + li a:after {
  display: none;
}

.blog-title,
.contact {
  color: var(--Fourth-color);
}

.blog-title:hover,
.contact:hover {
  color: var(--Third-color) !important;
}

.datetime {
  color: var(--Third-color) !important;
}

.subs-btn {
  padding: 0.6rem 1.3rem;
  margin: 30px 0;
  font-size: 0.95rem;
  color: var(--Third-color) !important;
  border: solid var(--Second-color) !important;
  background-color: transparent !important;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
}

.subs-btn:hover {
  background-color: var(--Second-color) !important;
  color: var(--First-color);
}
