@import url(https://unpkg.com/@webpixels/css@1.0/dist/index.css);

* {
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--First-color) var(--Third-color);
  scroll-behavior: smooth;
}

:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
  --dark-mode: #2a2c3b;
}

body {
  background-color: var(--Fourth-color) !important;
}

.btn {
  border: none;
  background-color: var(--Second-color) !important;
  color: var(--Fourth-color);
  border: none;
}

.btn:hover {
  background-color: var(--First-color) !important;
  color: var(--Fourth-color);
}

.btn-dark {
  background-color: var(--First-color) !important;
}

.btn-dark:hover {
  background-color: var(--Second-color) !important;
}

.text-color-Exdark {
  color: var(--Second-color) !important;
}

.text-color-dark {
  color: var(--Third-color) !important;
}

.text-color-light {
  color: var(--Fourth-color) !important;
}

.text-color-lg {
  color: var(--First-color) !important;
}

.text-color-sm {
  color: #5e5e5e !important;
}

/* ########## NavBar ############## */

.logo {
  width: 150px;
  /* filter: drop-shadow(1px 1px 1px #9ba3eb); */
}
nav {
  background-color: var(--Fourth-color) !important;
  height: 17vh;
  align-items: center;
  font-weight: 550;
  font-size: 1.1rem;
  z-index: 999;
}

.navbar-collapse {
  justify-content: flex-end;
  background-color: var(--Fourth-color) !important;
}

.dark-mode .navbar-collapse {
  background-color: var(--dark-mode) !important;
}

li {
  position: relative;
}

.nav-item .nav-link {
  padding: 15px 20px;
  position: relative;
  color: var(--Second-color) !important;
  font-weight: 550;
  font-size: 0.9rem;
}
.nav-item:hover .nav-link {
  color: var(--First-color) !important;
  animation: navlink-hover 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  position: relative;
}

@keyframes navlink-hover {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 8px;
  }
  100% {
    bottom: 0px;
  }
}

.nav-item:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 8px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background-color: var(--First-color) !important;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-item:hover:after {
  width: 100%;
  left: 0;
}

.nav-item .active {
  color: var(--First-color) !important;
}

.toggle-btn {
  background-color: transparent !important;
}

.toggle-btn .fa-moon {
  color: var(--Fourth-color);
}
.toggle-btn .fa-sun {
  color: var(--First-color);
}

.user-login .nav-item .nav-link:after {
  display: none;
}

.user-login-detail .nav-link {
  color: var(--Second-color) !important;
}

.user-login-detail .nav-link:hover {
  color: var(--First-color) !important;
}

.dropdown-menu {
  position: absolute;
  right: 0px !important;
  top: 55px !important;
  min-width: 100px !important;
  border-radius: 10px;
  background-color: var(--Fourth-color);
  border: none;
}
.dropdown-divider {
  width: 80%;
  border: 1px solid var(--Second-color);
  margin: 0 auto;
}
.dark-mode .dropdown-divider {
  border: 1px solid #3b3b3b;
}
.user-login .dropdown-menu {
  top: 60px !important;
  right: -20px !important;
}
.dropdown-item {
  color: var(--Second-color);
}
.user-login .dropdown-item {
  padding: 5px 20px !important;
}

.dark-mode .dropdown-menu {
  background-color: #1a202c !important;
}

.dark-mode .dropdown-item {
  color: var(--Fourth-color);
}
.dropdown-menu i {
  margin: 0 5px;
}
/* Custom Navbar Toggle Icon */
.navbar-toggler.custom-toggler .navbar-toggler-icon {
  display: none;
}

.navbar-toggler {
  background-color: var(--Second-color);
}

.navbar-toggler.custom-toggler .icon-bar {
  background-color: var(--Fourth-color);
  width: 25px;
  height: 3px;
  display: block;
  transition: all 0.2s;
  margin: 3px 0px;
  border-radius: 2px;
}
/* .navbar-toggler.custom-toggler .icon-bar:nth-of-type(1), */
.navbar-toggler.custom-toggler .icon-bar:nth-of-type(2) {
  width: 20px;
}

.navbar-toggler.custom-toggler:not(.collapsed) .icon-bar:nth-of-type(1) {
  transform: translateY(6px) rotate(45deg);
}

.navbar-toggler.custom-toggler:not(.collapsed) .icon-bar:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.custom-toggler:not(.collapsed) .icon-bar:nth-of-type(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.hamburger {
  display: none !important;
}
.user-details {
  display: flex;
}

@media screen and (max-width: 992px) {
  .hamburger {
    display: flex !important;
  }
  .user-details {
    display: none;
  }
}

@media screen and (max-width: 468px) {
  .user-details {
    display: flex;
    justify-content: center;
  }
  .toggle-modes {
    display: none !important;
  }

  .mobile-show {
    display: none !important;
  }
}

/* Dark Mode */

.dark-mode {
  background-color: var(--dark-mode) !important;
  color: white !important;
}
.dark-mode main {
  color: white !important;
}
.dark-mode #toggle {
  background-color: var(--dark-mode) !important;
}

.dark-mode nav {
  background: var(--dark-mode) !important;
}
.dark-mode .na v-item .nav-link {
  color: var(--Third-color) !important;
}
.dark-mode .nav-item .nav-link:hover {
  color: var(--Second-color) !important;
}
.dark-mode .nav-item .active {
  color: var(--Second-color) !important;
}
.dark-mode .text-color-sm {
  color: var(--Fourth-color) !important;
}
.dark-mode .text-color-lg {
  color: var(--Second-color) !important;
}

/* ############ Hero Section ####### */

#hero-img {
  height: 100vh;
  background-image: url(./images/header-img.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
  overflow-x: hidden;
}

#hero-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(36, 47, 155, 0.5);
}

/* ############ Hero Section2 ####### */

#hero-img2 {
  height: 70vh;
  background-color: var(--First-color);
  overflow-x: hidden;
}

#hero-container2 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #242f9bdd;
  overflow-x: hidden;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(
    to bottom,
    var(--Second-color),
    var(--Third-color)
  );
  bottom: 88%;
  right: 57%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(
    to bottom,
    var(--Second-color),
    var(--Third-color)
  );
  bottom: 20%;
  left: 90%;
  transform: translate(-40%, 38%);
}

.square:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #fafafa;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
}

.square2 {
  /* background: linear-gradient(to bottom, #1cd4af, #159b80); */
  top: 10%;
  left: 87%;
  transform: rotate(270deg);
}
