:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
}

.BlogCard-container {
  width: 500px;
  height: 500px;
  background-color: var(--Third-color);
  display: flex;
  align-items: center;
}
.BlogCard-container img {
  width: 500px;
  height: 400px;
  position: relative;
  left: 100px;
}

.blog-card-title {
  color: var(--Second-color);
}

.dark-mode .BlogCard-container .card p {
  color: var(--Fourth-color) !important;
}
.dark-mode .BlogCard-container .card small {
  color: var(--Third-color) !important;
}

.comment-heading {
  font-weight: 600;
  color: var(--First-color) !important;
}

.comments-card {
  background-color: var(--Third-color);
  color: var(--First-color) !important;
  border-radius: 10px;
  max-height: 80vh !important;
  overflow: auto;
}

.comment-card-items {
  margin: 10px 0px;
}

.comment-card-icon {
  padding: 10px 15px;
  margin: 5px 15px;
}

.comments-card .text-primary-emphasis {
  color: var(--First-color) !important;
}

.comments-card .card-text {
  font-size: 14px;
}

.dark-mode .comments-card .card-text {
  color: var(--First-color) !important;
}

.comment-form input,
.comment-form textarea {
  border: 2px solid var(--Third-color) !important;
  background-color: transparent;
}

@media screen and (max-width: 960px) {
  .BlogCard-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
    align-items: start;
  }
  .BlogCard-container img {
    width: 100%;
    left: 0;
  }
}

.recent-posts {
  height: 85vh !important;
  overflow-y: scroll;
}

.recent-posts .row .blog-card-title {
  color: var(--Second-color);
}
.recent-posts .row:hover .blog-card-title {
  color: var(--First-color);
}

/* width */
.recent-posts::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.recent-posts::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.recent-posts::-webkit-scrollbar-thumb {
  background: var(--First-color);
  border-radius: 10px;
}

.AllBlogs-sidebar img {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 400px) {
  .blogContent {
    padding: 0 !important;
  }
}
