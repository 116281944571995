.forgotPasswordForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-panel {
  background-color: var(--Third-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 100px auto;
}

.forgot-password-panel h2 {
  margin-bottom: 20px;
}

.forgot-password-panel p {
  margin-bottom: 20px;
}

.forgot-password-panel .panel-body .forget-password-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forget-password-form .form-group,
.forgot-password-btn,
.message {
  margin: 10px 0px;
  width: 80%;
  text-align: center;
}

.forget-password-form .form-group input,
.btn {
  border-radius: 10px;
}
