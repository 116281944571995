:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
}

#doCards {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  border: none;
}

.card-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Fourth-color);
  width: 100%;
  height: 100%;
  padding: 30px;
}

.card-overlay .card-title {
  font-size: 23px;
  text-align: center;
}

#card-overlay2 {
  transform: scale(1);
  background: linear-gradient(
    to bottom,
    var(--Second-color),
    var(--First-color)
  );
  /* opacity: 0.9; */
  transition: background 3s ease-in-out;
}

#doCards:hover #card-overlay2 {
  background: linear-gradient(
    to bottom,
    var(--First-color),
    var(--Second-color)
  );
}

#doCards .card-overlay::after {
  width: 100px;
  clip-path: circle(110vw at 100% 100%);
  position: relative;
  top: 0;
  left: 0;
  background-color: var(--Second-color);
}

#card-overlay2 a {
  text-align: center;
  color: var(--Fourth-color);
}

#card-overlay2 a small {
  margin: -2px;
  color: var(--Fourth-color) !important;
}

#card-overlay2 #card-title-after {
  position: relative;
  transform: translateY(2px);
}

#card-overlay2 .card-title {
  margin-bottom: 5px;
}
#doCards:hover #card-overlay2 .card-title {
  animation: card-overlay-title 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    forwards;
  color: var(--Fourth-color);
}

@keyframes card-overlay-title {
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 60px;
  }
  100% {
    margin-bottom: 20px;
  }
}

#card-overlay2 .card-text {
  width: 80%;
}
#doCards:hover #card-overlay2 .card-text {
  animation: card-overlay-text 0.5s ease-in-out forwards;
}

@keyframes card-overlay-text {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}

#card-overlay2 #card-title-after:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 20%;
  margin: 0 auto;
  position: absolute;
  background: var(--Fourth-color);
  transform: translateY(15px);
  transition: width 0.4s ease-in-out 0s, left 0.4s ease-in-out 0s;
  width: 0%;
}
#doCards:hover #card-overlay2 #card-title-after:after {
  /* left: 0; */
  animation: line 0.5s ease-in-out forwards;
}

@keyframes line {
  0% {
    width: 0%;
  }

  100% {
    width: 60%;
  }
}

/* services */

.ourServices {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ourServices h1::after {
  content: "";
  width: 10%;
  height: 4px;
  border-radius: 10px;
  margin: 10px 0;
  background-color: var(--First-color);
}

.service-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px;
}

/* serviceCard */

.card.serviceCard {
  background-color: var(--Third-color) !important;
  align-items: center;
  overflow: hidden;
  width: 25rem;
  margin: 20px;
}

.card.serviceCard::before {
  content: "";
  background-color: var(--Second-color) !important;
  width: 100%;
  height: 10px;
  width: 25rem;
}
.serviceCard .card-img {
  padding: 14px;
  width: 200px;
}
.serviceCard .card-body {
  padding: 0 20px 40px;
  color: var(--First-color);
  text-align: center;
}

@media screen and (max-width: 400px) {
  .card.serviceCard {
    width: 20rem;
  }
}
