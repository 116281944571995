:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
}

/*  Counter Section  */

#counter {
  background-color: var(--Second-color);
  display: block;
  overflow: hidden;
  text-align: center;
  padding: 80px 0;
}
#counter .count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin: 20px 0;
  height: 200px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--Fourth-color);
  text-align: center;
  border-radius: 10px;
}

#counter .count.active {
  background-color: var(--First-color) !important;
}

#counter .count:hover {
  background-color: var(--First-color);
}

#counter .count:hover .count-content {
  animation: zoom 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

#counter .number {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .pricing-items {
    padding-top: 0;
  }
  .pricing-item.active {
    top: 20px;
    margin-bottom: 40px;
  }
}
