.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: black;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--First-color);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--First-color);
  box-shadow: 0 0 2px var(--Second-color);
}

/* ##### QR Code ##### */
.qr-code-container {
  text-align: center;
}

.qr-code {
  width: 256px;
  height: 300px;
  margin: 15px;
}

.qr-code-container p {
  margin-top: 10px;
  font-size: 16px;
}

.verify-payment-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.verify-payment-container .form-group input {
  width: 60%;
  padding: 10px 15px;
  background-color: transparent;
  border: 2px solid var(--First-color);
border-radius: 10px;  
}

.verify-payment-container .form-group input:focus {
  outline: 1px solid var(--First-color);
 }

.verify-payment-container .form-group label{
  margin: 10px;
  font-weight: bolder;
  font-size: 1.2em;
}

@media screen and (max-width: 500px) {
  .internshipScheduleCard{
    width: 90vw;
  }

  .verify-payment-container .form-group{
    width: 100%;
   }
  
  .verify-payment-container form{
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .verify-payment-container .form-group input {
    width: 100%;
   } 
}
