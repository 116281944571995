:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
}

.accordion-item {
  margin: 10px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.accordion-header .accordion-button {
  background-color: var(--First-color);
  color: white;
  font-size: 17px;
}

.accordion-header .accordion-button.collapsed {
  background-color: var(--Fourth-color);
  color: midnightblue;
}

.dark-mode .accordion-item {
  margin: 5px 0;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  border: none !important;
}

.dark-mode .accordion-header .accordion-button {
  background-color: var(--First-color);
  color: white;
  font-size: 17px;
}

.dark-mode .accordion-header .accordion-button.collapsed {
  background-color: #373e4e34;
}
