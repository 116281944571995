:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
}

.ContactContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 400px) {
  .ContactContainer {
    padding: 4rem 1rem;
  }
  .form {
    max-width: 95vw !important;
  }
}

.form {
  width: 100%;
  max-width: 70vw;
  background-color: var(--Fourth-color);
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
}
.contact-form {
  background-color: var(--Second-color);
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, var(--First-color));
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: var(--Second-color);
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contact-form form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: var(--First-color) !important;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contact-form .input-container {
  position: relative;
  margin: 1rem 0;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  outline: none;
  background: none;
  border: 2px solid var(--Third-color);
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

.contact-form textarea::-webkit-scrollbar {
  display: none;
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  background-color: var(--Second-color);
  border-color: var(--First-color);
  box-shadow: none;
  color: white;
}

.contact-form textarea {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.btn-close {
  width: 0.5em;
  height: 0.5em;
}

.contact-form form input::placeholder,
.contact-form form textarea::placeholder {
  color: lightgrey;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: white;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--First-color);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--First-color);
  box-shadow: 0 0 0 0.25rem var(--First-color);
}

.valid-feedback {
  color: var(--First-color);
}

.contact-form .ContactBtn {
  padding: 0.6rem 1.3rem;
  font-size: 0.95rem;
  color: var(--First-color);
  border: solid #242f9b;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
}

.contact-form .ContactBtn:hover {
  background-color: var(--First-color) !important;
  color: var(--Second-color);
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: var(--First-color);
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.information i {
  color: var(--Second-color);
  margin-right: 10px;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, var(--First-color), var(--Second-color));
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid var(--First-color);
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.invalid {
  border: 2px solid red;
  animation: shaky 0.5s ease;
}

@keyframes shaky {
  10%,
  90% {
    transform: translateX(-5px) translateY(3px);
  }
  20%,
  80% {
    transform: translateX(5px) translateY(-3px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-5px) translateY(3px);
  }
  40%,
  60% {
    transform: translateX(5px) translateY(-3px);
  }
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .ContactBtn {
    padding: 0.45rem 1.2rem;
  }
}
