.multisteps-form__progress {
  margin: 80px 80px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.multisteps-form__progress-btn {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  padding-top: 20px;
  color: rgba(108, 117, 125, 0.7);
  text-indent: -9999px;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
}

@media (min-width: 500px) {
  .multisteps-form__progress-btn {
    text-indent: 0;
  }
}

.multisteps-form__progress-btn:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 13px;
  height: 13px;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.15s linear 0s,
    -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s,
    transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s,
    transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
    -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid currentColor;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 3;
}

.multisteps-form__progress-btn:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}

.multisteps-form__progress-btn:first-child:after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  color: var(--Second-color);
}

.multisteps-form__progress-btn.js-active:before {
  -webkit-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}

.internship-form {
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000;
  background-color: var(--Fourth-color);
  overflow: hidden;
  padding: 60px;
}

.internship-form label,
.internship-form select {
  color: black;
}

.internship-form input,
.internship-form select{
  border: 2px solid var(--First-color);
  color: black;
  background-color: transparent;
}

.internship-form option {
  background-color: var(--Fourth-color);
  padding: 10px;
}

.internship-form input:focus,
.internship-form select:focus {
  background-color: transparent;
  box-shadow: 0px 0px 4px var(--Second-color) !important;
  border-color: var(--First-color);
  box-shadow: none;
  color: black;
}

.form-selection{
  background-color: transparent !important;
}

/* customSelectStyles.css */

.css-13cymwt-control, .css-t3ipsp-control{
  background-color: transparent !important;
  border: none;
  margin: 0;
  padding: .25rem 1rem;
  border: 2px solid var(--First-color) !important;
  border-radius: .375rem !important;
}

.select__menu{
  background-color: var(--Fourth-color) !important;
}

.select__option--is-focused {
  background-color: var(--Second-color) !important;
  color: var(--Fourth-color )!important;
}

.select__option--is-selected {
  background-color: var(--Second-color) !important;
  color: var(--Fourth-color )!important;
}

.select__placeholder {
  color: #6c757d;
}

.select__single-value {
  color: #495057;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: black;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--First-color);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--First-color);
  box-shadow: 0 0 2px var(--Second-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--First-color);
}
.was-validated .form-check-input:valid,
.form-check-input.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--First-color);
}

/* Price Cards */

.pricing-card {
  overflow: hidden;
  border-radius: 20px;
}
.pricing-card .text-dark {
  color: var(--Second-color) !important;
}

.pricing-card .card-header {
  padding: 50px 0 10px 0 !important;
}

.pricing-card .card-body,
.pricing-card .card-header {
  background-color: var(--Third-color);
  border: none;
}

.pricing-card-active {
  background-color: var(--Second-color) !important;
  border: none;
}

.pricing-card-active .text-dark {
  color: var(--Third-color) !important;
}

.pricing-card-active h2 {
  color: var(--Third-color) !important;
}

.pricing-card .card-body .list-group-item {
  background-color: var(--Fourth-color);
  border: 1px solid #cccccc;
}

input[type="button"],
input[type="reset"],
.submit-btn {
  margin: 20px;
  color: var(--Fourth-color) !important;
  border: none;
}

.button-row {
  display: flex;
  justify-content: space-between;
}

.form-details {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.form-details h2 {
  color: var(--First-color);
  margin: 30px;
}
.form-details strong {
  margin: 10px 40px;
  color: var(--First-color);
}
.dark-mode .form-details strong {
  margin: 10px 40px;
  color: var(--Fourth-color);
}

.dark-mode .form-details strong {
  color: var(--dark-mode);
}


.form-control:disabled,
.form-control[readonly] {
  background-color: var(--Fourth-color);
}

/* Interview Schedule */

.internshipScheduleCard {
  border-radius: 20px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000;
  background-color: var(--Fourth-color);
  overflow: hidden;
  padding: 60px;
  width: 70vw;
  margin: 100px;
}

.design .big-circle::after {
  background: var(--Fourth-color);
  left: 71%;
  top: 40%;
}
.design .square {
  bottom: 8%;
  border-radius: 20px;
}
.design .square::after {
  background: var(--Fourth-color);
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .internshipScheduleCard {
    padding: 20px;
    margin: 50px 10px;
  }
  .design .square,
  .design .square::after,
  .design .big-circle,
  .design .big-circle::after {
    display: none;
  }
}
