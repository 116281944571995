
.testimonial_section {
  display: block;
  overflow: hidden;
  margin: 80px 0 20px 0;
}

.testimonial_section:after {
  display: block;
  clear: both;
  content: "";
}

.about_content {
  background-color: var(--First-color);
  padding-top: 77px;
  padding-right: 210px;
  padding-bottom: 62px;
  position: relative;
}

.about_content .background_layer {
  background-color: var(--First-color);
  width: auto;
  margin-left: -200px;
  right: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.layer_content {
  position: relative;
  z-index: 9;
  height: 100%;
}

.section_title {
  margin-bottom: 24px;
  position: relative;
}

.section_title:after {
  display: block;
  clear: both;
  content: "";
}
.section_title h5 {
  color: var(--Fourth-color);
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: var(--Fourth-color);
  margin-top: -5px;
  margin-bottom: 6px;
}
.section_title h2 {
  font-family: "Titillium Web";
  font-weight: 300;
  font-size: 45px;
  line-height: 50px;
  padding-bottom: 51px;
  margin-bottom: 0px;
  color: var(--Fourth-color);
}

.section_title h2 strong {
  font-weight: 600 !important;
  width: 100%;
  display: block;
}

.heading_line {
  position: relative;
}

.heading_line span {
  transition: all 0.5s ease-in-out 0s;
  position: relative;
}

.heading_line span:after {
  content: "";
  right: auto;
  left: 69px;
  position: absolute;
  bottom: 28px;
  width: 17px;
  margin-left: 0;
  border-bottom-width: 3px;
  border-bottom-color: var(--Fourth-color);
  border-bottom-style: solid;
}

.heading_line:after {
  content: "";
  left: 1%;
  margin-left: 0;
  position: absolute;
  bottom: 28px;
  width: 59px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: var(--Second-color);
}

.section_title p {
  color: var(--Fourth-color);
  margin: 0 0 15px;
}

.layer_content a {
  color: var(--Fourth-color);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

.layer_content a i {
  font-size: 18px;
  vertical-align: middle;
}

.layer_content a:hover {
  color: var(--Second-color);
}

.testimonial_box {
  margin-top: 60px !important;
  position: relative;
}

.testimonial_container {
  background-color: var(--Second-color);
  margin-left: -170px !important;
  position: relative;
}

.background_layer {
  background-color: var(--Second-color);
  width: auto;
  margin-right: -200px;
  right: 0;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.layer_content {
  position: relative;
  z-index: 9;
  height: 100%;
}

.testimonials {
  margin: 10px 0 10px 0;
  padding: 30px 0px 60px 50px;
  position: relative;
}

.testimonial_content {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.13);
  margin-left: 135px;
  margin-top: 69px;
  padding: 45px 40px 45px 40px;
  width: 35vw;
  z-index: 1;
  position: relative;
  background-color: var(--Fourth-color);
  transition: all 0.5s ease-in-out 0s;
}
@media screen and (max-width: 868px) {
  .testimonial_content {
    width: 80vw;
  }

  /* .background_layer {
    display: none;
  } */
}

.dark-mode .testimonial_content {
  background-color: var(--dark-mode) !important;
}

.testimonial_caption {
  margin-bottom: 15px;
  position: relative;
}

.testimonial_caption:after {
  content: "";
  width: 30px;
  display: block;
  height: 2px;
  text-align: center;
  margin-top: 6px;
  background-color: var(--Second-color);
  position: absolute;
}

.testimonial_caption h6 {
  padding-top: 0;
  margin-bottom: -5px;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  color: var(--Second-color);
}

.testimonial_content .testimonial_caption span {
  font-size: 12px;
  color: #9f9f9f;
  margin: 0;
}

.dark-mode .testimonial_caption span {
  color: var(--Fourth-color);
}

.testimonial_content p {
  padding: 0;
  margin: 0;
  padding-top: 10px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #5d6576;
  font-style: italic;
}

.dark-mode .testimonial_content p {
  color: var(--Fourth-color);
}

.testimonial_img {
  border: none;
  position: absolute;
  left: 50px;
  top: 50px;
}

.testimonial_img img {
  border: 5px solid var(--Fourth-color);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  width: 160px;
  height: 180px;
  object-fit: cover;
}

.layer_content .swiper-button-prev,
.layer_content .swiper-button-next {
  position: absolute;
  top: 150px !important;
  right: 10 px;
  border-radius: 0;
  display: block;
  background: var(--Second-color);
  outline: 0;
  text-align: center;
  line-height: 34px;
  width: 34px !important;
  height: 34px !important;
  color: var(--Fourth-color);
  transition: all 0.3s ease-in-out;
}

.swiper-button-prev {
  right: 40px !important;
  left: initial !important;
  margin: -20px 20px 0 0px !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: var(--First-color);
}

.swiper-button-next {
  margin: -20px 10px 0 0px !important;
  right: 5px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 14px !important;
}

@media all and (max-width: 991px) {
  .testimonial_section .container {
    max-width: 80vw;
    padding: 0 !important;
    margin: 0 auto !important;
  }
  .about_content {
    padding-right: 15px !important;
  }

  .background_layer {
    width: 200% !important;
  }

  .testimonial_section .testimonial_box {
    margin-top: -40px !important;
  }

  .background_layer {
    width: 200% !important;
    margin-left: -200px;
  }

  .about_content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .testimonial_container {
    margin-left: -15px !important;
  }

  .testimonials {
    margin: 0px 0 20px 0;
  }

  .testimonial_content {
    margin-left: -36px !important;
  }
  .testimonial_img {
    display: none;
  }
}
