.BlogSection .swiper {
  width: 95%;
  overflow: visible;
}

.blog-card {
  background-color: var(--Fourth-color) !important;
}

.dark-mode .blog-card {
  background-color: var(--dark-mode) !important;
}

.blog-card .blog-card-title {
  color: var(--Second-color);
}
.blog-card:hover .blog-card-title {
  color: var(--Second-color);
}

.blog-card:hover .card-body p {
  color: var(--Second-color);
}

.BlogSlides {
  overflow: hidden;
  width: 98%;
  margin: 10px auto;
}

.BlogSection .swiper-button-prev,
.BlogSection .swiper-button-next {
  background-color: var(--Second-color);
  border-radius: 5px;
  width: 40px !important;
  cursor: pointer;
  top: -80px !important;
  transition: background-color 0.3s ease;
}

.BlogSection .swiper-button-prev {
  right: 50px !important;
}
.BlogSection .swiper-button-next {
  right: 10px !important;
}

.BlogSection .swiper-button-prev::after,
.BlogSection .swiper-button-next::after {
  color: white;
  font-size: 20px !important;
}

.BlogSection .swiper-button-prev:hover,
.BlogSection .swiper-button-next:hover {
  background-color: var(--First-color);
}

.BlogCard-header {
  padding: 0 20px;
}

.BlogCard-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
